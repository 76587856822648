////////////////////////////////////////////////////////////
// CANVAS
////////////////////////////////////////////////////////////
var stage;
var canvasW = 0;
var canvasH = 0;

/*!
 *
 * START GAME CANVAS - This is the function that runs to setup game canvas
 *var stageW = 768;
var stageH = 800;
 */
function initGameCanvas(w = 700, h = 800) {
  console.log('INIT initGameCanvas');
  var gameCanvas = document.getElementById('gameCanvas');
  gameCanvas.width = w;
  gameCanvas.height = h;

  canvasW = w;
  canvasH = h;
  stage = new createjs.Stage('gameCanvas');

  createjs.Touch.enable(stage);
  stage.enableMouseOver(20);
  stage.mouseMoveOutside = true;

  createjs.Ticker.framerate = 60;
  createjs.Ticker.addEventListener('tick', tick);
}

var guide = false;
var canvasContainer, gameContainer, wheelOuterContainer, lightsContainer;
var guideline, bg, logo;

$.wheel = {};
$.wheelInner = {};
$.ticket = {};
$.light = {};

/*!
 *
 * BUILD GAME CANVAS ASSERTS - This is the function that runs to build game canvas asserts
 *
 */
function buildGameCanvas() {
  canvasContainer = new createjs.Container();
  gameContainer = new createjs.Container();
  wheelContainer = new createjs.Container();
  wheelOuterContainer = new createjs.Container();
  wheelPinContainer = new createjs.Container();
  lightsContainer = new createjs.Container();

  logo = new createjs.Bitmap(loader.getResult('logo'));

  //game
  bgWheel = new createjs.Bitmap(loader.getResult('bgWheel'));

  itemWheel = new createjs.Bitmap(loader.getResult('itemWheel'));
  centerReg(itemWheel);
  itemWheelCentre = new createjs.Bitmap(loader.getResult('itemWheelCentre'));
  centerReg(itemWheelCentre);
  itemWheel.x =
    itemWheelCentre.x =
    wheelOuterContainer.x =
    wheelPinContainer.x =
    lightsContainer.x =
      wheelX;
  itemWheel.y =
    itemWheelCentre.y =
    wheelOuterContainer.y =
    wheelPinContainer.y =
    lightsContainer.y =
      wheelY;

  itemArrow = new createjs.Bitmap(loader.getResult('itemArrow'));
  itemArrow.regX = 27;
  itemArrow.regY = 14;
  itemArrow.x = arrowX;
  itemArrow.y = arrowY;

  // buttonSpin = new createjs.Bitmap(loader.getResult('buttonSpin'));
  // centerReg(buttonSpin);
  // buttonSpin.x = canvasW / 100 * 52;
  // buttonSpin.y = canvasH / 100 * 55;

  itemPin = new createjs.Bitmap(loader.getResult('itemPin'));
  centerReg(itemPin);
  itemPin.x = -500;

  itemStatusBg = new createjs.Shape();
  // itemStatusBg.graphics.beginFill('red');
  // gameData.shape = itemStatusBg.graphics.beginFill('red').command;
  // itemStatusBg.graphics.drawRoundRectComplex(
  //   (canvasW / 100) * 13.9,
  //   (canvasH / 100) * 27.3,
  //   360,
  //   63,
  //   5,
  //   5,
  //   5,
  //   5
  // );

  statusTxt = new createjs.Text();

  var _frameW = 22;
  var _frameH = 22;
  var _frame = {
    regX: _frameW / 2,
    regY: _frameH / 2,
    height: _frameH,
    count: 2,
    width: _frameW,
  };
  var _animations = {
    off: { frames: [0], speed: 1 },
    on: { frames: [1], speed: 1 },
  };

  itemLightData = new createjs.SpriteSheet({
    images: [loader.getResult('itemLight').src],
    frames: _frame,
    animations: _animations,
  });

  itemLightAnimate = new createjs.Sprite(itemLightData, 'off');
  itemLightAnimate.framerate = 20;
  itemLightAnimate.x = -100;

  wheelContainer.addChild(
    wheelOuterContainer,
    itemWheelCentre,
    itemWheel,
    lightsContainer,
    itemArrow,
    wheelPinContainer
  );
  gameContainer.addChild(itemPin, itemLightAnimate);

  canvasContainer.addChild(wheelContainer, gameContainer);
  stage.addChild(canvasContainer);
}

/*!
 *
 * CANVAS LOOP - This is the function that runs for canvas loop
 *
 */
function tick(event) {
  updateGame();
  stage.update(event);
}

/*!
 *
 * CANVAS MISC FUNCTIONS
 *
 */
function centerReg(obj) {
  obj.regX = obj.image.naturalWidth / 2;
  obj.regY = obj.image.naturalHeight / 2;
}
