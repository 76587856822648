////////////////////////////////////////////////////////////
// MAIN
////////////////////////////////////////////////////////////
var stageW = 800;
var stageH = 800;

/*!
 *
 * START BUILD GAME - This is the function that runs build game
 *
 */
function initMain() {
  $('#canvasHolder').show();

  initGameCanvas(stageW, stageH);
  buildGameCanvas();
  buildGameButton();

  if (!gameSettings.enableFixedResult) {
    initPhysics(stageW, stageH, wheelX, wheelY);
  }
  drawWheel();

  startGame();
}

/*!
 *
 * GAME RESIZE - This is the function that runs to resize and centralize the game
 *
 */
function resizeGameFunc() {
  setTimeout(function () {
    $('#gameCanvas').css('width', 350);
    $('#gameCanvas').css('height', 350);

    $(window).scrollTop(0);
  }, 100);
}
