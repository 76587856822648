var gameSettingsTM = {
  gameChance: 2, //total chances for game 1
};

var textDisplayTM = {
  chancesText: 'x [NUMBER]',
};

var playerDataTM = { chance: 0, score: 0, point: 0, bet: 0 };
var gameDataTM = {
  spinning: false,
  stopped: true,
  rotateEaseNum: 0,
  rotateInnerNum: 0,
  wheelNum: 0,
  wheelInnerNum: 0,
  lightNum: 16,
  ticketX: 0,
  shape: '',
  touch: false,
  paused: true,
  spinDirection: true,
  spinSpeed: 0,
  velocity: 0,
  spindType: false,
  showStatus: false,
  fixedRotate: -1,
  fixedInnerRotate: -1,
};

/*!
 *
 * GAME BUTTONS - This is the function that runs to setup button event
 *
 */
function buildGameButtonTM() {
  gameDataTM.physicsEngine = false;

  buttonSpinTM.cursor = 'pointer';
  buttonSpinTM.addEventListener('click', function (evt) {
    //getResult(1,0);
    startSpinWheelTM(true);
  });
}

/*!
 *
 * DISPLAY PAGES - This is the function that runs to display pages
 *
 */
var curPageTM = '';

function goPageTM(page) {
  curPageTM = page;

  gameContainerTM.visible = false;

  var targetContainer = null;
  targetContainer = gameContainerTM;
  startGameTM();

  if (targetContainer != null) {
    targetContainer.visible = true;
    targetContainer.alpha = 0;
    TweenMax.to(targetContainer, 0.5, { alpha: 1, overwrite: true });
  }
}

function startGameTM() {
  //memberpayment
  playerDataTM.chance = gameDataTM.startChance = gameSettingsTM.gameChance;
  playerDataTM.score = playerDataTM.point = 0;

  itemGame1TM.visible = true;

  generateTicketsTM();
  animateInsertTicketTM();

  gameDataTM.wheelNum = -1;
  gameDataTM.wheelInnerNum = -1;
}

/*!
 *
 * GENERATE TICKETS - This is the function that runs to generate tickets
 *
 */
var maxDisplayTicketTM = 5;

function generateTicketsTM() {
  ticketContainerTM.removeAllChildren();

  var startX = (canvasWTM / 100) * 65;
  var startY = (canvasHTM / 100) * 48.5;
  gameDataTM.ticketX = startX;

  gameDataTM.totalTickets = gameDataTM.startChance;
  gameDataTM.totalTickets =
    gameDataTM.totalTickets >= maxDisplayTicketTM
      ? maxDisplayTicketTM
      : gameDataTM.totalTickets;
  chanceTxtTM.text = textDisplayTM.chancesText.replace(
    '[NUMBER]',
    gameDataTM.startChance
  );

  for (var n = 0; n < gameDataTM.totalTickets; n++) {
    $.ticket[n] = itemTicketTM.clone();
    $.ticket[n].x = startX;
    $.ticket[n].y = startY;
    startX -= $.ticket[n].image.naturalWidth + 1;

    ticketContainerTM.addChild($.ticket[n]);
  }
}

/*!
 *
 * ANIMATE TICKETS - This is the function that runs to animate tickets
 *
 */
function animateInsertTicketTM() {
  playSound('soundTicket', 5);

  for (var n = 0; n < gameDataTM.totalTickets; n++) {
    $.ticket[n].oriX = $.ticket[n].x;
    TweenMax.to($.ticket[n], 0.5, {
      x: $.ticket[n].oriX + 48,
      overwrite: true,
    });
  }
}

function animateNextTicketTM() {
  // playSound('soundTicket');
  var startX = gameDataTM.ticketX + 36;

  var distance = 0;
  if (gameDataTM.startChance > maxDisplayTicketTM) {
    distance = maxDisplayTicketTM - playerDataTM.chance;
  } else {
    distance = gameDataTM.startChance - playerDataTM.chance;
  }
  var distanceTicket = playerDataTM.chance >= maxDisplayTicketTM ? 1 : distance;

  for (var n = 0; n < gameDataTM.totalTickets; n++) {
    var newX = 0;
    if (n < distanceTicket) {
      newX = gameDataTM.ticketX + $.ticket[n].image.naturalWidth + 1;
    } else {
      newX = startX;
      startX -= $.ticket[n].image.naturalWidth + 1;
    }
    TweenMax.to($.ticket[n], 0.5, {
      x: newX,
      overwrite: true,
      onComplete: animateNextTicketDoneTM,
    });
  }
}

function animateNextTicketDoneTM() {
  if (playerDataTM.chance >= maxDisplayTicketTM) {
    var startX = gameDataTM.ticketX + 36;
    for (var n = 0; n < gameDataTM.totalTickets; n++) {
      $.ticket[n].x = startX;
      startX -= $.ticket[n].image.naturalWidth + 1;
    }
  }
}

/*!
 *
 * UPDATE STAT - This is the function that runs to update game stat
 *
 */
function updateStatTM() {
  chanceTxtTM.text = textDisplayTM.chancesText.replace(
    '[NUMBER]',
    playerDataTM.chance
  );
  // creditTxt.text = textDisplay.creditText.replace('[NUMBER]', addCommas(Math.floor(playerData.point)));
}

/*!
 *
 * SPIN WHEEL - This is the function that runs to spin wheel
 *
 */
function startSpinWheelTM(con) {
  // toggleInstruction(false);
  gameDataTM.wheelNum = -1;
  gameDataTM.wheelInnerNum = -1;
  gameDataTM.showStatus = false;

  playerDataTM.chance--;
  playerDataTM.chance = playerDataTM.chance < 0 ? 0 : playerDataTM.chance;

  updateStatTM();
  animateNextTicketTM();
}

/*!
 *
 * SAVE GAME - This is the function that runs to fixed result
 *
 */
function getResultTM(wheelNum, wheelInnerNum) {
  gameDataTM.fixedRotate = wheelNum;
  gameDataTM.fixedInnerRotate = wheelInnerNum;
}

function setChances(chance) {
  playerDataTM.chance =
    gameDataTM.startChance =
    gameSettingsTM.gameChance =
      chance;
}

window.ticketMachineControl = {
  start: startSpinWheelTM,
  setTickets: setChances,
};
