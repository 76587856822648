/*!
 *
 * START CANVAS PRELOADER - This is the function that runs to preload canvas asserts
 *
 */
function initPreload() {
  if (window.location.pathname !== '/slot') {
    return;
  }

  $(window).resize(function () {
    resizeGameFunc();
  });

  resizeGameFunc();

  loader = new createjs.LoadQueue(false);
  manifest = [
    { src: 'assets/img/wheel/item_wheel_center-2.png', id: 'itemWheelCentre' },
    { src: 'assets/img/wheel/item_arrow.png', id: 'itemArrow' },
    { src: 'assets/img/wheel/item_wheel.png', id: 'itemWheel' },
    { src: 'assets/img/wheel/item_pin.png', id: 'itemPin' },
    { src: 'assets/img/wheel/item_light.png', id: 'itemLight' },
  ];

  for (var n = 0; n < wheel_arr.length; n++) {
    if (wheel_arr[n].src != '') {
      manifest.push({ src: wheel_arr[n].src, id: 'wheel' + n });
    }

    if (wheel_arr[n].highlight != '') {
      manifest.push({ src: wheel_arr[n].highlight, id: 'wheelH' + n });
    }
  }

  soundOn = true;

  if (soundOn) {
    manifest.push({ src: 'assets/media/click.ogg', id: 'soundClick' });
    manifest.push({ src: 'assets/media/loss.ogg', id: 'soundLoss' });
    manifest.push({ src: 'assets/media/win.ogg', id: 'soundWin' });
    // manifest.push({ src: 'assets/media/lossall.ogg', id: 'soundLossall' });
    // manifest.push({ src: 'assets/media/jackpot.ogg', id: 'soundJackpot' });
    manifest.push({ src: 'assets/media/spin.ogg', id: 'soundSpin' });
    manifest.push({ src: 'assets/media/spinning.ogg', id: 'soundSpinning' });
    // manifest.push({src: 'assets/media/ticket.ogg', id: 'soundTicket'});
    manifest.push({ src: 'assets/media/tone.ogg', id: 'soundTone' });
    manifest.push({ src: 'assets/media/result.ogg', id: 'soundResult' });
    manifest.push({ src: 'assets/media/arrow.ogg', id: 'soundArrow' });
    manifest.push({ src: 'assets/media/select.ogg', id: 'soundSelect' });
    manifest.push({ src: 'assets/media/start.ogg', id: 'soundStart' });

    createjs.Sound.alternateExtensions = ['mp3'];
    loader.installPlugin(createjs.Sound);
  }

  loader.addEventListener('complete', initMain);
  // loader.addEventListener("fileload", fileComplete);
  loader.addEventListener('error', handleFileError);
  // loader.on("progress", handleProgress, this);
  loader.loadManifest(manifest);
}

/*!
 *
 * CANVAS FILE HANDLE EVENT - This is the function that runs to handle file error
 *
 */
function handleFileError(evt) {
  console.log('error ', evt);
}

if (!window.wheelControl) {
  window.wheelControl = {};
}

window.wheelControl['initPreload'] = initPreload;
