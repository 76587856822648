////////////////////////////////////////////////////////////
// CANVAS LOADER
////////////////////////////////////////////////////////////

/*!
 *
 * START CANVAS PRELOADER - This is the function that runs to preload canvas asserts
 *
 */
function initPreloadTM() {
  $(window).resize(function () {
    resizeGameFuncTM();
  });
  resizeGameFuncTM();

  loaderTM = new createjs.LoadQueue(false);
  manifest = [
    { src: 'assets/img/ticket/item_side.png', id: 'itemSide' },
    { src: 'assets/img/ticket/item_game1.png', id: 'itemGame1' },
    { src: 'assets/img/ticket/item_ticket.png', id: 'itemTicket' },
    { src: 'assets/img/ticket/button_spin.png', id: 'buttonSpin' },
  ];

  if (soundOn) {
    manifest.push({ src: 'assets/media/click.ogg', id: 'soundClick' });
    manifest.push({ src: 'assets/media/loss.ogg', id: 'soundLoss' });
    manifest.push({ src: 'assets/media/win.ogg', id: 'soundWin' });
    manifest.push({ src: 'assets/media/lossall.ogg', id: 'soundLossall' });
    manifest.push({ src: 'assets/media/jackpot.ogg', id: 'soundJackpot' });
    manifest.push({ src: 'assets/media/spin.ogg', id: 'soundSpin' });
    manifest.push({ src: 'assets/media/spinning.ogg', id: 'soundSpinning' });
    manifest.push({ src: 'assets/media/ticket.ogg', id: 'soundTicket' });
    manifest.push({ src: 'assets/media/tone.ogg', id: 'soundTone' });
    manifest.push({ src: 'assets/media/result.ogg', id: 'soundResult' });
    manifest.push({ src: 'assets/media/arrow.ogg', id: 'soundArrow' });
    manifest.push({ src: 'assets/media/select.ogg', id: 'soundSelect' });
    manifest.push({ src: 'assets/media/start.ogg', id: 'soundStart' });

    createjs.Sound.alternateExtensions = ['mp3'];
    loaderTM.installPlugin(createjs.Sound);
  }

  loaderTM.addEventListener('complete', handleCompleteTM);
  loaderTM.addEventListener('fileload', fileCompleteTM);
  loaderTM.addEventListener('error', handleFileErrorTM);
  // loaderTM.on("progress", handleProgress, this);
  loaderTM.loadManifest(manifest);
}

/*!
 *
 * CANVAS FILE COMPLETE EVENT - This is the function that runs to update when file loaded complete
 *
 */
function fileCompleteTM(evt) {
  var item = evt.item;
  //console.log("Event Callback file loaded ", evt.item.id);
}

/*!
 *
 * CANVAS FILE HANDLE EVENT - This is the function that runs to handle file error
 *
 */
function handleFileErrorTM(evt) {
  console.log('error ', evt);
}

/*!
 *
 * CANVAS PREloaderTM COMPLETE - This is the function that runs when preloaderTM is complete
 *
 */
function handleCompleteTM() {
  initMainTM();
}

window.loaderTMControl = {
  init: initPreloadTM,
};
