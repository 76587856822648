////////////////////////////////////////////////////////////
// MAIN
////////////////////////////////////////////////////////////
var stageWTM = 600;
var stageHTM = 600;
var contentWTM = 400;
var contentHTM = 400;

/*!
 *
 * START BUILD GAME - This is the function that runs build game
 *
 */
function initMainTM() {
  $('#canvasTicketHolder').show();

  initGameCanvasTM(stageWTM, stageHTM);
  buildGameCanvasTM();
  buildGameButtonTM();

  goPageTM('game');
}

var windowWTM = (windowH = 0);
var scalePercentTM = 0;
var offsetTM = { x: 0, y: 0, left: 0, top: 0 };

/*!
 *
 * GAME RESIZE - This is the function that runs to resize and centralize the game
 *
 */
function resizeGameFuncTM() {
  setTimeout(function () {
    // $('.mobileRotate').css('left', checkContentWidth($('.mobileRotate')));
    // $('.mobileRotate').css('top', checkContentHeight($('.mobileRotate')));

    windowWTM = window.innerWidth;
    windowH = window.innerHeight;

    scalePercentTM = windowWTM / contentWTM;
    if (contentHTM * scalePercentTM > windowH) {
      scalePercentTM = windowH / contentHTM;
    }

    scalePercentTM = scalePercentTM > 1 ? 1 : scalePercentTM;

    if (windowWTM > stageWTM && windowH > stageHTM) {
      if (windowWTM > stageWTM) {
        scalePercentTM = windowWTM / stageWTM;
        if (stageHTM * scalePercentTM > windowH) {
          scalePercentTM = windowH / stageHTM;
        }
      }
    }
    console.log('scale', scalePercentTM);
    var newCanvasW = stageWTM * scalePercentTM;
    var newCanvasH = stageHTM * scalePercentTM;

    offsetTM.left = 0;
    offsetTM.top = 0;

    if (newCanvasW > windowWTM) {
      offsetTM.left = -(newCanvasW - windowWTM);
    } else {
      offsetTM.left = windowWTM - newCanvasW;
    }

    if (newCanvasH > windowH) {
      offsetTM.top = -(newCanvasH - windowH);
    } else {
      offsetTM.top = windowH - newCanvasH;
    }

    offsetTM.x = 0;
    offsetTM.y = 0;

    if (offsetTM.left < 0) {
      offsetTM.x = Math.abs(offsetTM.left / scalePercentTM / 2);
    }
    if (offsetTM.top < 0) {
      offsetTM.y = Math.abs(offsetTM.top / scalePercentTM / 2);
    }

    $('#ticketMachineCanvas').css('width', 340);
    $('#ticketMachineCanvas').css('height', 300);

    $('#ticketMachineCanvas').css('left', offsetTM.left / 2);
    $('#ticketMachineCanvas').css('top', -20);

    $(window).scrollTop(0);

    // resizeCanvas();
  }, 100);
}
