////////////////////////////////////////////////////////////
// INIT
////////////////////////////////////////////////////////////
var stageWidthTM,
  stageHeightTM = 0;
var isLoadedTM = false;

/*!
 *
 * DOCUMENT READY
 *
 */
$(function () {
  $(window).resize(function () {
    resizeLoaderFuncTM();
  });
  resizeLoaderFuncTM();
  checkBrowserTM();
});

/*!
 *
 * LOADER RESIZE - This is the function that runs to centeralised loader when resize
 *
 */
function resizeLoaderFuncTM() {
  stageWidthTM = $(window).width();
  stageHeightTM = $(window).height();
}

/*!
 *
 * BROWSER DETECT - This is the function that runs for browser and feature detection
 *
 */
var browserSupportTM = false;

function checkBrowserTM() {
  var canvasElTM = document.createElement('canvas');
  if (canvasElTM.getContext) {
    browserSupportTM = true;
  }

  if (browserSupportTM) {
    if (!isLoadedTM) {
      isLoadedTM = true;

      initPreloadTM();
    }
  } else {
    //browser not support
    $('#notSupportHolder').show();
  }
}
