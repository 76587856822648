////////////////////////////////////////////////////////////
// GAME v2.8
////////////////////////////////////////////////////////////

/*!
 *
 * GAME SETTING CUSTOMIZATION START
 *
 */
var gameSettings = {
  enableFixedResult: true, //option to have fixed result by API, enabling this will disable 2D physics engine
  enablePercentage: false, //option to have result base on percentage, enabling this will disable 2D physics engine
  spinDirection: true, //true to spin right, false to spin left
  spinSpeed: 30, //wheel spinning speed
  touchSpin: true, //touch to spin (true/false)
  gamePlayType: true, //game play type; true for game1, false for game2
  gameChance: 999, //total chances for game 1
  gameBetPoint: 0, //total bet point for game 2
  gameCanBet: 0, //total bet increase for game 2
  gameMaxBet: 0, //max bet for game 2
  firstWheelRadius: 300, //first wheel radius for slot color feature
  secondWheelRadius: 0, //second wheel radius for slot color feature
  defaultStatusBgColor: '#655643', //status bacgkround color
  secondWheel: false, //option to display second wheel; (true/false)
};

var textDisplay = {
  statusText_arr: [
    'TURBINE SUA SORTE',
    'TURBINANDO...',
    '[NUMBER]PTS*',
    'WIN [NUMBER]PTS**',
    'BETTER LUCK NEXT TIME!',
    'JACKPOT [NUMBER]',
    'YOU LOSS ALL POINTS',
  ],
};

var wheel_arr = [
  //01
  {
    src: 'assets/img/wheel/item_wheel_01.png',
    highlight: 'assets/img/wheel/item_wheel_01_h.png',
    color: '#FDCC09',
    regX: 1,
    regY: 223,
    point: 1,
    type: 0,
    percent: 0,
    slot: {
      color: '#FDCC09',
      highlightColor: '#FFE600',
      stroke: 5,
      strokeColor: '#fff',
      fontSize: 31,
      text: 'Pix\n2x',
      textY: 220,
      textColor: '#fff',
    },
  },
  //02
  {
    src: 'assets/img/wheel/item_wheel_06.png',
    highlight: 'assets/img/wheel/item_wheel_06_h.png',
    color: '#91328B',
    regX: 1,
    regY: 223,
    point: 2,
    type: 0,
    percent: 0,
    slot: {
      color: '#91328B',
      highlightColor: '#91328B',
      stroke: 5,
      strokeColor: '#fff',
      fontSize: 31,
      text: '1 Bilhete\nDourado',
      textY: 220,
      textColor: '#fff',
    },
  },
  //03
  {
    src: 'assets/img/wheel/item_wheel_02.png',
    highlight: 'assets/img/wheel/item_wheel_02_h.png',
    color: '#F37621',
    regX: 1,
    regY: 223,
    point: 3,
    type: 1,
    percent: 0,
    slot: {
      color: '#F37621',
      highlightColor: '#F37621',
      stroke: 5,
      strokeColor: '#fff',
      fontSize: 31,
      text: 'Sem\nbônus',
      textY: 220,
      textColor: '#fff',
    },
  },
  //04
  {
    src: 'assets/img/wheel/item_wheel_11.png',
    highlight: 'assets/img/wheel/item_wheel_11_h.png',
    color: '#DA2027',
    regX: 1,
    regY: 223,
    point: 4,
    type: 0,
    percent: 0,
    slot: {
      color: '#DA2027',
      highlightColor: '#DA2027',
      stroke: 5,
      strokeColor: '#fff',
      fontSize: 31,
      text: 'Pix\n3x',
      textY: 220,
      textColor: '#fff',
    },
  },
  //05
  {
    src: 'assets/img/wheel/item_wheel_03.png',
    highlight: 'assets/img/wheel/item_wheel_03_h.png',
    color: '#7DB544',
    regX: 1,
    regY: 223,
    point: 5,
    type: 0,
    percent: 0,
    slot: {
      color: '#7DB544',
      highlightColor: '#7DB544',
      stroke: 5,
      strokeColor: '#fff',
      fontSize: 31,
      text: '2 Bilhetes\nDourados',
      textY: 220,
      textColor: '#fff',
    },
  },
  //06
  {
    src: 'assets/img/wheel/item_wheel_01.png',
    highlight: 'assets/img/wheel/item_wheel_01_h.png',
    color: '#FDCC09',
    regX: 1,
    regY: 223,
    point: 6,
    type: 0,
    percent: 0,
    slot: {
      color: '#FDCC09',
      highlightColor: '#FFE600',
      stroke: 5,
      strokeColor: '#fff',
      fontSize: 31,
      text: 'Pix\n2x',
      textY: 220,
      textColor: '#fff',
    },
  },
  //07
  {
    src: 'assets/img/wheel/item_wheel_03.png',
    highlight: 'assets/img/wheel/item_wheel_03_h.png',
    color: '#7DB544',
    regX: 1,
    regY: 223,
    point: 7,
    type: 0,
    percent: 0,
    slot: {
      color: '#7DB544',
      highlightColor: '#7DB544',
      stroke: 5,
      strokeColor: '#fff',
      fontSize: 31,
      text: '2 Bilhetes\nDourados',
      textY: 220,
      textColor: '#fff',
    },
  },
  //08
  {
    src: 'assets/img/wheel/item_wheel_05.png',
    highlight: 'assets/img/wheel/item_wheel_05_h.png',
    color: '#3F559A',
    regX: 1,
    regY: 223,
    point: 8,
    type: 0,
    percent: 0,
    slot: {
      color: '#3F559A',
      highlightColor: '#3F559A',
      stroke: 5,
      strokeColor: '#fff',
      fontSize: 31,
      text: 'Pix\n5x',
      textY: 220,
      textColor: '#fff',
    },
  },
  //09
  {
    src: 'assets/img/wheel/item_wheel_06.png',
    highlight: 'assets/img/wheel/item_wheel_06_h.png',
    color: '#91328B',
    regX: 1,
    regY: 223,
    point: 9,
    type: 7,
    percent: 0,
    slot: {
      color: '#91328B',
      highlightColor: '#91328B',
      stroke: 5,
      strokeColor: '#fff',
      fontSize: 31,
      text: '1 Bilhete\nDourado',
      textY: 220,
      textColor: '#fff',
    },
  },
  //10
  {
    src: 'assets/img/wheel/item_wheel_01.png',
    highlight: 'assets/img/wheel/item_wheel_01_h.png',
    color: '#FDCC09',
    regX: 1,
    regY: 223,
    point: 10,
    type: 0,
    percent: 0,
    slot: {
      color: '#FDCC09',
      highlightColor: '#FFE600',
      stroke: 5,
      strokeColor: '#fff',
      fontSize: 31,
      text: 'Pix\n2x',
      textY: 220,
      textColor: '#fff',
    },
  },
  //11
  {
    src: 'assets/img/wheel/item_wheel_04.png',
    highlight: 'assets/img/wheel/item_wheel_04_h.png',
    color: '#1A9DD8',
    regX: 1,
    regY: 223,
    point: 11,
    type: 0,
    percent: 0,
    slot: {
      color: '#1A9DD8',
      highlightColor: '#1A9DD8',
      stroke: 5,
      strokeColor: '#fff',
      fontSize: 31,
      text: '3 Bilhetes\nDourados',
      textY: 220,
      textColor: '#fff',
    },
  },
  //12
  {
    src: 'assets/img/wheel/item_wheel_02.png',
    highlight: 'assets/img/wheel/item_wheel_02_h.png',
    color: '#F37621',
    regX: 1,
    regY: 223,
    point: 12,
    type: 1,
    percent: 0,
    slot: {
      color: '#F37621',
      highlightColor: '#F37621',
      stroke: 5,
      strokeColor: '#fff',
      fontSize: 31,
      text: 'Sem\nbônus',
      textY: 220,
      textColor: '#fff',
    },
  },
];

/*!
 *
 * GAME SETTING CUSTOMIZATION END
 *
 */
var wheelX = 400;
var wheelY = 393;
var arrowX = 400;
var arrowY = 65;

var playerData = { chance: 0, score: 0, point: 0, bet: 0 };
var gameData = {
  spinning: false,
  stopped: true,
  rotateEaseNum: 0,
  rotateInnerNum: 0,
  wheelNum: 0,
  wheelInnerNum: 0,
  lightNum: 16,
  ticketX: 0,
  shape: '',
  touch: false,
  paused: true,
  spinDirection: true,
  spinSpeed: 0,
  velocity: 0,
  spindType: false,
  showStatus: false,
  fixedRotate: -1,
  fixedInnerRotate: -1,
};
var betData = {
  interval: null,
  timer: 0,
  timerMax: 300,
  timerMin: 10,
  betpoint: 0,
  betNumber: 0,
  betNumberPlus: 0,
};
var lightData = { side: false, num: 0 };
var arrowData = { move: true, speed: 0, rotatation: 0, count: 0 };

/*!
 *
 * GAME BUTTONS - This is the function that runs to setup button event
 *
 */
function buildGameButton() {
  gameData.physicsEngine = true;
  if (gameSettings.enableFixedResult) {
    gameData.physicsEngine = false;
  }

  if (gameSettings.enablePercentage) {
    createPercentage();
    gameData.physicsEngine = false;
  }
}

function toggleWheelActive(con) {
  if (con) {
    wheelContainer.cursor = 'pointer';
  } else {
    wheelContainer.cursor = 'default';
  }
}

/*!
 *
 * START GAME - This is the function that runs to start play game
 *
 */

function startGame() {
  toggleWheelActive(true);

  //memberpayment
  playerData.chance = gameData.startChance = gameSettings.gameChance;
  playerData.score = playerData.point = 0;

  statusTxt.text = textDisplay.statusText_arr[0];
  gameData.shape.style = gameSettings.defaultStatusBgColor;
  gameData.touch = false;
  gameData.paused = false;
  gameData.wheelNum = -1;
  gameData.wheelInnerNum = -1;
  gameData.spinDirection = gameSettings.spinDirection;
  gameData.spinSpeed = gameSettings.spinSpeed;
  gameData.spindType = true;
  gameData.spinning = false;
  gameData.stopped = true;
  gameData.winPoint = 0;

  if (gameData.physicsEngine) {
    warmUpWheel();
  }
  animateLights('static');
  animateSpinStatus(statusTxt, false);
}

/*!
 *
 * STOP GAME - This is the function that runs to stop play game
 *
 */
function stopGame() {
  stopSoundLoop('soundSpinning');
  TweenMax.killAll();

  for (var n = 0; n < wheel_arr.length; n++) {
    TweenMax.killTweensOf($.wheel[n]);

    $.wheel[n].visible = false;

    //slot color feature
    if (
      wheel_arr[n].slot != undefined &&
      wheel_arr[n].slot.highlightColor != ''
    ) {
      TweenMax.killTweensOf($.wheel['slotH' + n]);
      $.wheel['slotH' + n].visible = false;
    }
  }

  gameData.touch = false;
  gameData.paused = true;
  toggleWheelActive(false);
}

/*!
 *
 * UPDATE GAME - This is the function that runs to loop game update
 *
 */
function updateGame() {
  if (gameData.physicsEngine) {
    updatePhysics();
  }

  if (!gameData.physicsEngine) {
    wheelPinContainer.rotation = wheelOuterContainer.rotation;

    if (gameData.spinning && arrowData.move) {
      var wheelRadius = 360 / wheel_arr.length;

      if (
        Math.abs(wheelPinContainer.rotation) >=
        arrowData.count * wheelRadius
      ) {
        playSound('soundArrow');
        if (gameData.spinDirection) {
          itemArrow.rotation = -30;
        } else {
          itemArrow.rotation = 30;
        }
        for (var n = 0; n < 10; n++) {
          arrowData.count++;
          if (
            arrowData.count * wheelRadius >=
            Math.abs(wheelPinContainer.rotation)
          ) {
            n = 10;
          }
        }
      }

      arrowData.speed = arrowData.speed * 0.9 + (0 - itemArrow.rotation) / 50;
      itemArrow.rotation = itemArrow.rotation + arrowData.speed;
    }
  }
}

/*!
 *
 * DRAW WHEELS - This is the function that runs to draw wheels
 *
 */
function drawWheel() {
  var wheelRadius = 360 / wheel_arr.length;
  for (var n = 0; n < wheel_arr.length; n++) {
    //pin
    var thisPin = itemPin.clone();

    getAnglePosition(thisPin, 0, 0, 265, wheelRadius * n);
    wheelPinContainer.addChild(thisPin);

    //wheel
    var thisWheel = new createjs.Bitmap(loader.getResult('wheel' + n));
    thisWheel.regX = wheel_arr[n].regX;
    thisWheel.regY = wheel_arr[n].regY;
    thisWheel.x = 0;
    thisWheel.y = 0;
    setDirection(thisWheel, thisPin);

    if (gameData.physicsEngine) {
      thisWheel.rotation -= wheelRadius * Math.floor(wheel_arr.length / 4);
    } else {
      thisWheel.rotation = wheelRadius * n + 90;
    }

    $.wheel[n] = new createjs.Bitmap(loader.getResult('wheelH' + n));
    $.wheel[n].regX = wheel_arr[n].regX;
    $.wheel[n].regY = wheel_arr[n].regY;
    $.wheel[n].x = 0;
    $.wheel[n].y = 0;
    $.wheel[n].rotation = thisWheel.rotation;
    $.wheel[n].visible = false;

    wheelOuterContainer.addChild(thisWheel, $.wheel[n]);

    //slot color feature
    if (wheel_arr[n].slot != undefined) {
      $.wheel['slot' + n] = new createjs.Container();
      $.wheel['slot' + n].rotation = thisWheel.rotation;
      wheelOuterContainer.addChild($.wheel['slot' + n]);

      if (wheel_arr[n].slot.color != '') {
        var newSlotColor = new createjs.Shape();
        newSlotColor.graphics.clear();
        newSlotColor.graphics.beginFill(wheel_arr[n].slot.color);

        var endAngle = Number((360 / wheel_arr.length) * (Math.PI / 180));
        newSlotColor.graphics
          .moveTo(0, 0)
          .arc(0, 0, gameSettings.firstWheelRadius, 0, endAngle, false)
          .closePath();
        newSlotColor.rotation = -90;

        $.wheel['slot' + n].addChild(newSlotColor);
      }

      //highlight
      if (wheel_arr[n].slot.highlightColor != '') {
        $.wheel['slotH' + n] = new createjs.Shape();
        $.wheel['slotH' + n].graphics.clear();
        $.wheel['slotH' + n].graphics
          .beginFill(wheel_arr[n].slot.highlightColor)
          .setStrokeStyle(wheel_arr[n].slot.stroke)
          .beginStroke(wheel_arr[n].slot.strokeColor);
        $.wheel['slotH' + n].visible = false;

        $.wheel['slotH' + n].graphics
          .moveTo(0, 0)
          .arc(0, 0, gameSettings.firstWheelRadius, 0, endAngle, false)
          .closePath();
        $.wheel['slotH' + n].rotation = -90;
        $.wheel['slot' + n].addChild($.wheel['slotH' + n]);
      }

      //text
      if (wheel_arr[n].slot.text != '') {
        $.wheel['slotText' + n] = new createjs.Text();
        $.wheel['slotText' + n].font =
          wheel_arr[n].slot.fontSize + 'px libel_suitregular';
        $.wheel['slotText' + n].lineHeight = wheel_arr[n].slot.fontSize;
        $.wheel['slotText' + n].color = wheel_arr[n].slot.textColor;
        $.wheel['slotText' + n].textAlign = 'center';
        $.wheel['slotText' + n].textBaseline = 'alphabetic';
        $.wheel['slotText' + n].text = wheel_arr[n].slot.text;
        $.wheel['slotText' + n].regY = wheel_arr[n].slot.textY;
        $.wheel['slotText' + n].rotation = 360 / wheel_arr.length / 2;

        $.wheel['slot' + n].addChild($.wheel['slotText' + n]);
      }
    }
  }

  //lights
  gameData.lightNum = 16;
  var wheelRadius = 360 / gameData.lightNum;
  for (var n = 0; n < gameData.lightNum; n++) {
    $.light[n] = itemLightAnimate.clone();
    getAnglePosition($.light[n], 0, 0, 300, wheelRadius * n);

    lightsContainer.addChild($.light[n]);
  }
}

/*!
 *
 * SPIN WHEEL - This is the function that runs to spin wheel
 *
 */
function startSpinWheel(con) {
  //memberpayment
  if (
    typeof memberData != 'undefined' &&
    memberSettings.enableMembership &&
    !memberData.ready
  ) {
    return;
  }

  if (gameData.spinning) {
    return;
  }

  if (playerData.chance <= 0) {
    return;
  }

  // toggleInstruction(false);
  gameData.wheelNum = -1;
  gameData.wheelInnerNum = -1;
  gameData.showStatus = false;
  statusTxt.text = textDisplay.statusText_arr[1];
  animateSpinStatus(statusTxt, true);
  gameData.shape.style = gameSettings.defaultStatusBgColor;

  for (var n = 0; n < wheel_arr.length; n++) {
    TweenMax.killTweensOf($.wheel[n]);
    $.wheel[n].visible = false;

    //slot color feature
    if (
      wheel_arr[n].slot != undefined &&
      wheel_arr[n].slot.highlightColor != ''
    ) {
      TweenMax.killTweensOf($.wheel['slotH' + n]);
      $.wheel['slotH' + n].visible = false;
    }
  }

  // playerData.chance--;
  // playerData.chance = playerData.chance < 0 ? 0 : playerData.chance;

  proceedSpin();
}

function proceedSpin(result) {
  if (result != undefined) {
    gameData.fixedRotate = result.fixedRotate;
    gameData.fixedInnerRotate = result.fixedInnerRotate;
  }

  playSound('soundSpin');
  playSoundLoop('soundSpinning');

  gameData.spinDirection = gameSettings.spinDirection;
  if (!gameData.physicsEngine) {
    startSpinWheelBig();
  } else {
    startPhysicsSpin();
  }
  // startSpinWheelInner();
  animateLights('spin');
}

function getAnglePosition(obj, x1, y1, radius, angle) {
  obj.x = x1 + radius * Math.cos((angle * Math.PI) / 180);
  obj.y = y1 + radius * Math.sin((angle * Math.PI) / 180);
}

/*!
 *
 * START SPIN WHEEL INNER - This is the function that runs to spin inner wheel
 *
 */
function startSpinWheelBig() {
  gameData.spindType = true;
  gameData.spinning = true;
  gameData.stopped = false;
  arrowData.count = 0;

  wheelOuterContainer.rotation = 0;
  var wheelRadius = 360 / wheel_arr.length;
  var rotateNum = gameData.fixedRotate;

  if (rotateNum == -1) {
    if (gameSettings.enablePercentage) {
      shuffle(gameData.percentageArray);
      rotateNum = gameData.percentageArray[0];
    } else {
      rotateNum = Math.floor(Math.random() * wheel_arr.length);
    }
  }

  var innerNum = rotateNum;
  if (gameData.spinDirection) {
    rotateNum = wheel_arr.length - rotateNum;
  }
  if (!gameData.spinDirection) {
    rotateNum = Math.abs(wheelRadius * rotateNum + wheelRadius / 2);
    rotateNum += 90;
  } else {
    rotateNum = Math.abs(wheelRadius * rotateNum - wheelRadius / 2);
    rotateNum -= 90;
  }

  var totalRound = Math.floor(gameData.spinSpeed / 3.5);
  var totalRoundNum = 360 * totalRound;
  var toRotate = -(totalRoundNum + rotateNum);
  if (gameData.spinDirection) {
    toRotate = Math.abs(totalRoundNum + rotateNum);
  }

  TweenMax.to(wheelOuterContainer, totalRound, {
    rotation: toRotate,
    overwrite: true,
    ease: Circ.easeOut,
    onComplete: function () {
      gameData.wheelNum = innerNum;
      TweenMax.to(wheelOuterContainer, 1, {
        overwrite: true,
        onComplete: function () {
          checkWheelScore();
        },
      });
    },
  });
}

/*!
 *
 * CHECK WHEEL SCORE - This is the function that runs to check wheel score
 *
 */
function checkWheelScore() {
  if (gameData.wheelNum == -1) {
    return;
  }

  // if(gameSettings.secondWheel && gameData.wheelInnerNum == -1){
  // 	return;
  // }

  if (gameData.showStatus) {
    return;
  }

  stopSoundLoop('soundSpinning');
  gameData.showStatus = true;

  $.wheel[gameData.wheelNum].visible = true;
  animateWheelSegment($.wheel[gameData.wheelNum], true);

  //slot color feature
  if (
    wheel_arr[gameData.wheelNum].slot != undefined &&
    wheel_arr[gameData.wheelNum].slot.highlightColor != ''
  ) {
    $.wheel['slotH' + gameData.wheelNum].visible = true;
    animateWheelSegment($.wheel['slotH' + gameData.wheelNum], true);
    wheelOuterContainer.setChildIndex(
      $.wheel['slot' + gameData.wheelNum],
      wheelOuterContainer.numChildren - 1
    );
  }

  playSound('soundSelect');

  //gameData.wheelNum = 8;
  //gameData.wheelInnerNum = 1;
  var wheelSegmentNumber = wheel_arr[gameData.wheelNum].point;
  var wheelSegmentType = wheel_arr[gameData.wheelNum].type;

  const updateStat = function () {};

  TweenMax.to(playerData, 1, {
    overwrite: true,
    onComplete: function () {
      playSound('soundTone');

      if (wheelSegmentType == 1) {
        //loss all
        playSound('soundLossall');
        statusTxt.text = textDisplay.statusText_arr[6];
        animateLights('lose');
        playerData.score = playerData.bet = 0;
        TweenMax.to(playerData, 1, {
          point: playerData.score,
          overwrite: true,
          onUpdate: updateStat,
        });
        gameData.shape.style = wheel_arr[gameData.wheelNum].color;
        gameData.spinning = false;

        proceedCheckGameEnd();
      } else {
        var winPoint = wheelSegmentNumber;
        // if(!gameSettings.gamePlayType){
        // 	winPoint = winPoint * playerData.bet;
        // 	playerData.bet = 0;
        // }
        statusTxt.text = textDisplay.statusText_arr[3].replace(
          '[NUMBER]',
          addCommas(winPoint)
        );

        if (winPoint > 0) {
          //win
          if (wheelSegmentType == 2) {
            // playSound('soundJackpot');
            statusTxt.text = textDisplay.statusText_arr[5].replace(
              '[NUMBER]',
              addCommas(winPoint)
            );
          } else {
            // playSound('soundWin');
          }

          gameData.shape.style = wheel_arr[gameData.wheelNum].color;
          playerData.score += winPoint;
          gameData.winPoint = winPoint;

          animateLights('win');
          TweenMax.to(playerData, 1, {
            point: playerData.score,
            overwrite: true,
          });
        } else {
          //no win
          playSound('soundLoss');
          statusTxt.text = textDisplay.statusText_arr[4];
          animateLights('lose');
        }

        gameData.spinning = false;
        proceedCheckGameEnd();
      }
    },
  });
}

function proceedCheckGameEnd() {
  TweenMax.to(itemStatusBg, 0, {
    overwrite: true,
    onComplete: function () {
      playSound('soundResult');
      animateLights('static');
      saveGame(playerData.score);
      stopGame();
    },
  });
}

/*!
 *
 * ANIMATE WHEEL LIGHTS - This is the function that runs to animate wheel lights
 *
 */
function animateLights(type) {
  TweenMax.killTweensOf(lightData);
  TweenMax.killTweensOf(itemWheel);

  switch (type) {
    case 'static':
      lightData.side = true;
      loopAnimateLights();
      break;

    case 'spin':
      lightData.num = 0;
      loopAnimateSpinLights();
      break;

    case 'win':
      lightData.side = true;
      loopAnimateWinLights();

      TweenMax.to(itemWheel, 3, {
        overwrite: true,
        onComplete: animateLights,
        onCompleteParams: ['static'],
      });
      break;

    case 'lose':
      for (var n = 0; n < gameData.lightNum; n++) {
        $.light[n].gotoAndStop('off');
        if (isEven(n)) {
          $.light[n].gotoAndStop('on');
        }
      }

      TweenMax.to(itemWheel, 3, {
        overwrite: true,
        onComplete: animateLights,
        onCompleteParams: ['static'],
      });
      break;
  }
}

function loopAnimateLights() {
  for (var n = 0; n < gameData.lightNum; n++) {
    $.light[n].gotoAndStop('off');
    if (lightData.side && isEven(n)) {
      $.light[n].gotoAndStop('on');
    }

    if (!lightData.side && !isEven(n)) {
      $.light[n].gotoAndStop('on');
    }
  }

  lightData.side = lightData.side == false ? true : false;
  TweenMax.to(lightData, 0.5, {
    overwrite: true,
    onComplete: loopAnimateLights,
  });
}

function loopAnimateSpinLights() {
  for (var n = 0; n < gameData.lightNum; n++) {
    $.light[n].gotoAndStop('off');
    if (n == lightData.num) {
      $.light[n].gotoAndStop('on');
    }
  }

  if (gameData.spinDirection) {
    lightData.num++;
    lightData.num = lightData.num >= gameData.lightNum ? 0 : lightData.num;
  } else {
    lightData.num--;
    lightData.num = lightData.num < 0 ? gameData.lightNum - 1 : lightData.num;
  }

  TweenMax.to(lightData, 0.05, {
    overwrite: true,
    onComplete: loopAnimateSpinLights,
  });
}

function loopAnimateWinLights() {
  for (var n = 0; n < gameData.lightNum; n++) {
    $.light[n].gotoAndStop('off');
    if (lightData.side) {
      $.light[n].gotoAndStop('on');
    }
  }

  lightData.side = lightData.side == false ? true : false;
  TweenMax.to(lightData, 0.1, {
    overwrite: true,
    onComplete: loopAnimateWinLights,
  });
}

/*!
 *
 * ANIMATE WHEEL SEGMENT - This is the function that runs to animate wheel segment
 *
 */
function animateWheelSegment(obj, con, alpha) {
  var tweenSpeed = 0.1;
  if (con) {
    var alphaNum = 1;
    if (alpha) {
      alpha = false;
      alphaNum = 0;
    } else {
      alpha = true;
    }
    TweenMax.to(obj, tweenSpeed, {
      alpha: alphaNum,
      overwrite: true,
      onComplete: animateWheelSegment,
      onCompleteParams: [obj, con, alpha],
    });
  } else {
    TweenMax.to(obj, tweenSpeed, { alpha: 0, overwrite: true });
  }
}

/*!
 *
 * ANIMATE STATUS - This is the function that runs to animate status
 *
 */
function animateSpinStatus(obj, con, alpha) {
  var tweenSpeed = 0.1;
  if (con) {
    var alphaNum = 1;
    if (alpha) {
      alpha = false;
      alphaNum = 0.5;
    } else {
      alpha = true;
    }
    TweenMax.to(obj, tweenSpeed, {
      alpha: alphaNum,
      overwrite: true,
      onComplete: animateSpinStatus,
      onCompleteParams: [obj, con, alpha],
    });
  } else {
    TweenMax.to(obj, tweenSpeed, { alpha: 1, overwrite: true });
  }
}

/*!
 *
 * SAVE GAME - This is the function that runs to fixed result
 *
 */
function getResult(wheelNum, wheelInnerNum) {
  gameData.fixedRotate = wheelNum;
  gameData.fixedInnerRotate = wheelInnerNum;
}

/*!
 *
 * PERCENTAGE - This is the function that runs to create result percentage
 *
 */
function createPercentage() {
  gameData.percentageArray = [];
  gameData.percentageInnerArray = [];

  //outer
  for (var n = 0; n < wheel_arr.length; n++) {
    if (!isNaN(wheel_arr[n].percent)) {
      if (wheel_arr[n].percent > 0) {
        for (var p = 0; p < wheel_arr[n].percent; p++) {
          gameData.percentageArray.push(n);
        }
      }
    }
  }
}

/*!
 *
 * OPTIONS - This is the function that runs to mute and fullscreen
 *
 */
function toggleSounds(con) {
  console.log('toggleSounds----->', con);
  toggleSoundInMute(!con);
  toggleMusicInMute(!con);
}

//
// function toggleFullScreen() {
//   if (!document.fullscreenElement &&    // alternative standard method
//       !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement ) {  // current working methods
//     if (document.documentElement.requestFullscreen) {
//       document.documentElement.requestFullscreen();
//     } else if (document.documentElement.msRequestFullscreen) {
//       document.documentElement.msRequestFullscreen();
//     } else if (document.documentElement.mozRequestFullScreen) {
//       document.documentElement.mozRequestFullScreen();
//     } else if (document.documentElement.webkitRequestFullscreen) {
//       document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
//     }
//   } else {
//     if (document.exitFullscreen) {
//       document.exitFullscreen();
//     } else if (document.msExitFullscreen) {
//       document.msExitFullscreen();
//     } else if (document.mozCancelFullScreen) {
//       document.mozCancelFullScreen();
//     } else if (document.webkitExitFullscreen) {
//       document.webkitExitFullscreen();
//     }
//   }
// }
//
// function toggleConfirm(con){
// 	confirmContainer.visible = con;
//
// 	if(con){
// 		TweenMax.pauseAll(true, true);
// 		gameData.paused = true;
// 	}else{
// 		TweenMax.resumeAll(true, true)
// 		gameData.paused = false;
// 	}
// }

if (!window.wheelControl) {
  window.wheelControl = {};
}

window.wheelControl['stop'] = stopGame;
window.wheelControl['setResult'] = getResult;
window.wheelControl['startSpinWheel'] = startSpinWheel;
window.wheelControl['toggleSounds'] = toggleSounds;
window.wheelControl['onComplete'] = function () {};

/*!
 *
 * SAVE GAME - This is the function that runs to save game
 *
 */
function saveGame(score) {
  console.log('saveGame----->', score);
  window.wheelControl.onComplete();
}
