var lotieConfig = {
  player: '#lottiePlayer',
  mode: 'chain',
  actions: [
    {
      state: 'loop',
      frames: [0, 50],
    },
    {
      state: 'loop',
      frames: [0, 300],
    },
    {
      state: 'loop',
      frames: [80, 300],
    },
  ],
};

function playChestJumping() {
  let lt = LottieInteractivity.create(lotieConfig);

  lt.jumpToInteraction(0);
}

function playWin() {
  let lt = LottieInteractivity.create(lotieConfig);

  lt.jumpToInteraction(1);
}

function play() {
  var player = document.getElementById('lottiePlayer');

  console.log('teste controls', player);
  if (player) {
    console.log('has player');
    player.play();
  }
}

function stop() {
  var player = document.getElementById('lottiePlayer');

  console.log('teste controls', player);
  if (player) {
    console.log('has player');
    player.stop();
  }
}

function stopWin() {
  let lt = LottieInteractivity.create(lotieConfig);

  lt.jumpToInteraction(2);
}

window.gameControl = {
  play: play,
  stop: stop,
  stopWin: stopWin,
  playChestJumping: playChestJumping,
  playWin: playWin,
};
