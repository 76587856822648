////////////////////////////////////////////////////////////
// CANVAS
////////////////////////////////////////////////////////////
var stageTM;
var canvasWTM = 0;
var canvasHTM = 0;

/*!
 *
 * START GAME CANVAS - This is the function that runs to setup game canvas
 *
 */
function initGameCanvasTM(w, h) {
  var ticketMachineCanvas = document.getElementById('ticketMachineCanvas');

  if (!ticketMachineCanvas) {
    return;
  }

  ticketMachineCanvas.width = w;
  ticketMachineCanvas.height = h;

  canvasWTM = w;
  canvasHTM = h;
  stageTM = new createjs.Stage('ticketMachineCanvas');

  createjs.Touch.enable(stageTM);
  stageTM.enableMouseOver(20);
  stageTM.mouseMoveOutside = true;

  createjs.Ticker.framerate = 60;
  createjs.Ticker.addEventListener('tick', tickTM);
}

var canvasContainerTM, gameContainerTM, ticketContainerTM;
$.ticket = {};

/*!
 *
 * BUILD GAME CANVAS ASSERTS - This is the function that runs to build game canvas asserts
 *
 */
function buildGameCanvasTM() {
  canvasContainerTM = new createjs.Container();

  gameContainerTM = new createjs.Container();

  ticketContainerTM = new createjs.Container();

  itemSideTM = new createjs.Bitmap(loaderTM.getResult('itemSide'));
  centerRegTM(itemSideTM);
  itemSideTM.x = (canvasWTM / 100) * 52;
  itemSideTM.y = (canvasHTM / 100) * 50;

  itemGame1TM = new createjs.Bitmap(loaderTM.getResult('itemGame1'));
  centerRegTM(itemGame1TM);
  itemGame1TM.x = (canvasWTM / 100) * 52;
  itemGame1TM.y = (canvasHTM / 100) * 50;

  buttonSpinTM = new createjs.Bitmap(loaderTM.getResult('buttonSpin'));
  // centerReg(buttonSpin);
  // buttonSpin.x = canvasW/100 * 40;
  // buttonSpin.y = canvasH/100 * 77;

  statusTxtTM = new createjs.Text();
  // statusTxt.font = "45px libel_suitregular";
  // statusTxt.color = "#fff";
  // statusTxt.textAlign = "center";
  // statusTxt.textBaseline = 'alphabetic';
  // statusTxt.text = 'PRONTO';
  // statusTxt.x = canvasW / 100 * 28;
  // statusTxt.y = canvasH / 100 * 34;

  itemTicketTM = new createjs.Bitmap(loaderTM.getResult('itemTicket'));
  itemTicketTM.regX = itemTicketTM.image.naturalWidth;
  itemTicketTM.x = -500;

  itemTicketMaskTM = new createjs.Shape();
  itemTicketMaskTM.graphics.beginFill('red');
  itemTicketMaskTM.graphics.drawRect(0, (canvasHTM / 100) * 48.5, 390, 70);
  itemTicketMaskTM.alpha = 0;
  ticketContainerTM.mask = itemTicketMaskTM;

  chanceTxtTM = new createjs.Text();
  chanceTxtTM.font = '45px libel_suitregular';
  chanceTxtTM.color = '#652312';
  chanceTxtTM.textAlign = 'center';
  chanceTxtTM.textBaseline = 'alphabetic';
  chanceTxtTM.text = '';
  chanceTxtTM.x = (canvasWTM / 100) * 75;
  chanceTxtTM.y = (canvasHTM / 100) * 57;

  gameContainerTM.addChild(
    itemTicketTM,
    itemSideTM,
    itemGame1TM,
    itemTicketMaskTM,
    ticketContainerTM,
    chanceTxtTM,
    statusTxtTM
  );

  canvasContainerTM.addChild(gameContainerTM);
  if (stageTM) {
    stageTM.addChild(canvasContainerTM);
  }
}

/*!
 *
 * CANVAS LOOP - This is the function that runs for canvas loop
 *
 */
function tickTM(event) {
  // updateGame();
  stageTM.update(event);
}

/*!
 *
 * CANVAS MISC FUNCTIONS
 *
 */
function centerRegTM(obj) {
  obj.regX = obj.image.naturalWidth / 2;
  obj.regY = obj.image.naturalHeight / 2;
}
